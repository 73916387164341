import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22')
];

export const server_loads = [0,2];

export const dictionary = {
		"/": [~3],
		"/blog": [~4],
		"/blog/[slug]": [5],
		"/contact-us": [6],
		"/dashboard": [7,[2]],
		"/dashboard/analytics": [8,[2]],
		"/dashboard/payment": [9,[2]],
		"/dashboard/profile": [10,[2]],
		"/dashboard/stats/bluesky/graph/[handle]/[stat]": [11,[2]],
		"/dashboard/stats/bluesky/top-users/[stat]": [~12,[2]],
		"/disclaimer": [13],
		"/faq": [14],
		"/graph/[handle]/[stat]": [~15],
		"/login": [16],
		"/privacy-policy": [17],
		"/signup": [18],
		"/stats/bluesky/graph/[handle]/[stat]": [19],
		"/stats/bluesky/top-users/[stat]": [~20],
		"/subscribe-now": [21],
		"/termsandconditions": [22]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: universal_hooks.reroute || (() => {}),
	transport: universal_hooks.transport || {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';